import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const ImageProcessor = ({
  image,
  withFallback = false,
  isBlog,
  ...props
}) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/image-fallback.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <>
      {!image && !!withFallback && (
        //* fallback image
        <img src={data.file.publicURL} alt={"Fallback"} {...props} />
      )}

      {!!image && !!image.node && !image.node.imageFile && !image.childImageSharp && (
        //* default image - no preprocessed image available
        <img src={image.node.sourceUrl} alt={image.node.altText} {...props} />
      )}

      {!!image && !image.node?.imageFile && !image.imageFile && !image.childImageSharp && (
        //* default image - no preprocessed image available
        <img src={image.sourceUrl} alt={image.altText} {...props} />
      )}

      {!!image && image?.node?.imageFile?.childImageSharp?.blogImage && !!isBlog && (
        //* fluid image - queried from wordpress, processed by the resolver, used on the blog pages
        <Img
          fluid={image.node.imageFile.childImageSharp.blogImage}
          alt={image.node.altText}
          {...props}
        />
      )}

      {!!image && image?.node?.imageFile?.childImageSharp?.fluid && !isBlog && (
        //* fluid image - queried from wordpress, processed by the resolver, used on single posts
        <Img
          fluid={image.node.imageFile.childImageSharp.fluid}
          alt={image.node.altText}
          {...props}
        />
      )}

      {!!image && image?.node?.imageFile?.childImageSharp?.fixed && (
        //* fixed image - queried from wordpress, processed by the resolver
        <Img
          fixed={image.node.imageFile.childImageSharp.fixed}
          alt={image.node.altText}
          {...props}
        />
      )}

      {!!image && image?.imageFile?.childImageSharp?.fluid && (
        //* fluid image - queried from wordpress, processed by the resolver
        <Img
          fluid={image.imageFile.childImageSharp.fluid}
          alt={image.altText}
          {...props}
        />
      )}

      {!!image && image?.imageFile?.childImageSharp?.fixed && (
        //* fixed image - queried from wordpress, processed by the resolver
        <Img
          fixed={image.imageFile.childImageSharp.fixed}
          alt={image.altText}
          {...props}
        />
      )}

      {!!image && image?.childImageSharp?.fluid && (
        //* fluid image - queried directly from gatsby
        <Img
          fluid={image.childImageSharp.fluid}
          alt={image.altText}
          {...props}
        />
      )}

      {!!image && image?.childImageSharp?.fixed && (
        //* fixed image - queried directly from gatsby
        <Img
          fixed={image.childImageSharp.fixed}
          alt={image.altText}
          {...props}
        />
      )}
    </>
  );
};

export default ImageProcessor;
